<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <v-col cols="4" class="text-center">
                    <v-menu
                      ref="birthdaySelector"
                      v-model="birthdaySelector"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model.trim="form.birthday"
                          label="생년월일"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        v-model="form.birthday"
                        locale="ko"
                        :active-picker.sync="activePicker"
                        :max="today"
                        min="1920-01-01"
                        @change="saveBirthday"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4" class="text-center">
                    <v-switch
                      v-model="form.isLunar"
                      color="primary"
                      :label="form.isLunar ? '음력' : '양력'"
                      class="mt-5 ml-10"
                    ></v-switch>
                  </v-col>
                  <v-col cols="4" class="text-left">
                    <v-switch
                      v-model="form.isMarry"
                      color="primary"
                      :label="form.isMarry ? '기혼' : '미혼'"
                      class="mt-5"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.addr"
                  v-validate="'max:50'"
                  maxlength="50"
                  :error-messages="errors.collect('addr')"
                  data-vv-name="addr"
                  label="주소"
                  hint="서울특별시 강남구 삼성동 123"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.emergency"
                  v-validate="'max:50'"
                  maxlength="50"
                  :error-messages="errors.collect('emergency')"
                  data-vv-name="emergency"
                  label="긴급연락"
                  hint="010-123-4567(가족 홍길동)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.hobby"
                  v-validate="'max:20'"
                  maxlength="20"
                  :error-messages="errors.collect('hobby')"
                  data-vv-name="hobby"
                  label="취미"
                  hint="골프,바둑"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-textarea
                  ref="txtaEtc"
                  v-model.trim="form.etc"
                  label="기타"
                  outlined
                  no-resize
                  rows="5"
                  counter="100"
                  :rules="rules"
                  @update:error="etcError"
                ></v-textarea>
              </v-col>
              <!-- <v-col cols="12" class="py-0">
                <v-card
                  tile
                  elevation="0"
                  class="ma-0 pa-0"
                >
                  <v-card-actions class="ma-0 pa-0">
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>print</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>get_app</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>phone_iphone</v-icon>
                    </v-btn>
                    <v-btn small text icon color="primary" disabled>
                      <v-icon small>local_post_office</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <span class="text-caption mr-2">{{ staffInfo.wname }}</span><span class="text-caption">{{ staffInfo.updatedAt }}</span>
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

export default {
  components: {
    baseModal
  },

  props: [ 'staffInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      email: '',
      birthday: '',
      isLunar: '',
      isMarry: '',
      addr: '',
      emergency: '',
      hobby: '',
      etc: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        addr: '주소',
        emergency: '긴급연락',
        hobby: '취미'
      }
    },
    // 중요: 텍스트박스의 글자수 체크 변수 - rules[0]() 함수는 true 나 문자열을 반환한다.
    rules: [ v => v.length <= 100 || '100자 이하의 내용을 입력해 주세요' ],
    activePicker: null, // 생일셀렉터 date picker 의 타입결정인자
    birthdaySelector: false, // 참고: 생일 셀렉터 메뉴
    chkValidate: true, // 폼 유효성 - 기타 셀렉트 100자 이상이면 false
    today: ''
  }),

  watch: {
    birthdaySelector (val) { // 중요: 생일 셀렉터의 초기모드를 년선택으로 한다
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },

  created () {
    this.today = this.$moment().format('YYYY-MM-DD')
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
    },

    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },

    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 넘겨받은 staffInfo 변수를 매칭시키는게 초기화라고 볼 수 있다.
      this.form.email = this.staffInfo.email
      this.form.birthday = this.staffInfo.birthday
      this.form.isLunar = this.staffInfo.isLunar
      this.form.isMarry = this.staffInfo.isMarry
      this.form.addr = this.staffInfo.addr
      this.form.emergency = this.staffInfo.emergency
      this.form.hobby = this.staffInfo.hobby
      this.form.etc = this.staffInfo.etc
    },

    open (title, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')

        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        // 중요: textarea 의 글자수가 100 이하로 조정되면 여기서 체크해서 처리한다.
        if (this.rules[0](this.$refs.txtaEtc.value) === true) {
          this.chkValidate = true
        }

        if (!this.chkValidate) throw new Error('저장할 수 없습니다. 기타 항목의 글자수를 줄여주세요.')

        // 폼의 내용 중 하나라도 변경된게 있는지 검사한다.
        const isChanged = await this.isChangedForm()
        if (isChanged) {
          // 변경된게 있으면 업데이트 한다.
          const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

          // 새로고침하지 않게 부모페이지의 값도 바꿔버린다.
          this.staffInfo = Object.assign(this.staffInfo, this.form)
        } else {
          // 변경이 없으면
          // throw new Error('변경된 내용이 없습니다.')
        }

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },

    // 참고: form 과 넘겨받은 staffInfo 중 달라진게 있는지 검사한다.
    async isChangedForm () {
      try {
        if (this.form.birthday !== this.staffInfo.birthday ||
          this.form.isLunar !== this.staffInfo.isLunar ||
          this.form.isMarry !== this.staffInfo.isMarry ||
          this.form.addr !== this.staffInfo.addr ||
          this.form.emergency !== this.staffInfo.emergency ||
          this.form.hobby !== this.staffInfo.hobby ||
          this.form.etc !== this.staffInfo.etc) {
          return true
        } else {
          return false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 참고: 생일 셀렉트 선택시 저장 함수
    saveBirthday (date) {
      this.$refs.birthdaySelector.save(date)
    },

    // 참고: 기타 텍스트 에어리어 에러 처리 이벤트 핸들러
    etcError () {
      this.chkValidate = false
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
