<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.school"
                  v-validate="'required|max:30'"
                  maxlength="30"
                  :error-messages="errors.collect('school')"
                  data-vv-name="school"
                  required
                  label="*학교"
                  hint="대한대학교"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.major"
                  v-validate="'required|max:30'"
                  maxlength="30"
                  :error-messages="errors.collect('major')"
                  data-vv-name="major"
                  required
                  label="전공"
                  hint="법과대학 법학과"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  ref="startY"
                  v-model="form.startY"
                  v-validate="'required'"
                  :error-messages="errors.collect('startY')"
                  data-vv-name="startY"
                  required
                  :rules="rules"
                  maxlength="4"
                  label="입학년도"
                  hint="2000"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  ref="endY"
                  v-model="form.endY"
                  v-validate="'required'"
                  :error-messages="errors.collect('endY')"
                  data-vv-name="endY"
                  required
                  :rules="rules"
                  maxlength="4"
                  label="졸업년도"
                  hint="2007"
                  class="pa-2"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.major"
                  maxlength="30"
                  required
                  label="전공"
                  hint="법과대학 법학과"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  ref="startY"
                  v-model="form.startY"
                  maxlength="4"
                  label="입학년도"
                  hint="2000"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  ref="endY"
                  v-model="form.endY"
                  maxlength="4"
                  label="졸업년도"
                  hint="2007"
                  class="pa-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'
import isNumber from '@/filters/isNumber'

export default {
  components: {
    baseModal
  },

  props: [ 'staffInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      email: '',
      degree: '', // 참고: 백엔드에 넘기기 위해 필요하다!
      type: 'degree', // 참고: 백엔드에 타입 정보를 알린다
      school: '',
      major: '',
      startY: '',
      endY: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        school: '학교'
        // major: '전공',
        // startY: '입학년도',
        // endY: '졸업년도'
      }
    },
    // 중요: 텍스트박스의 글자수 체크 변수 - rules[0]() 함수는 true 나 false를 반환한다.
    // rules: [ v => v.length <= 4 || false ],
    index: -1 // 수정 인덱스
  }),

  watch: {
    'form.startY': { // 입학년도 숫자만
      handler: function (val, oldVal) {
        if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
          if (isNumber(val)) {
            this.form.startY = oldVal
            this.$refs.startY.lazyValue = oldVal
          }
        }
      },
      deep: true
    },
    'form.endY': { // 졸업년도 숫자만
      handler: function (val, oldVal) {
        if (val.length > 0) { // 이게 없으면 무한루프 걸릴 수 있다.
          if (isNumber(val)) {
            this.form.endY = oldVal
            this.$refs.endY.lazyValue = oldVal
          }
        }
      },
      deep: true
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    isNumber, // 숫자만 인지 체크하는 필터
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 참고: staffInfo 변수중 이메일만 받는다
      this.form.email = this.staffInfo.email

      // 참고: 아래의 폼 변수는 따로 초기화 해야 한다.
      this.form.type = 'degree'
      this.form.school = ''
      this.form.major = ''
      this.form.startY = ''
      this.form.endY = ''

      this.index = -1 // 참고: 수정용 인덱스 초기화
    },
    open (title, options, index = -1) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index
          this.form = Object.assign(this.form, this.staffInfo.degree[index])
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')
        // console.log(this.form)

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        const { school, major } = this.form

        // 한번 더 숫자이외의 것을 제거한다
        this.form.startY = this.form.startY.replace(/[^0-9]/g, '')
        this.form.endY = this.form.endY.replace(/[^0-9]/g, '')

        // 등록하거나 수정할 객체 생성
        const ioObject = { school, major, startY: this.form.startY, endY: this.form.endY }

        if (!this.staffInfo.degree) {
          // 참고: 기존의 배열이 없으면.. 최초의 배열 생성
          const imsiArr = []
          imsiArr.push(ioObject)
          this.form.degree = JSON.stringify(imsiArr)
          // 중요: 부모의 배열에 넣어줘야 함
          this.staffInfo.degree.push(ioObject)
        } else {
          // 참고: 기존의 배열이 있으면 .. 편집과 등록에 따라 다르게 처리
          if (this.index > -1) {
            // 편집모드인 경우 해당 항목을 대치한다.
            const isChanged = await this.isChangedForm(ioObject)
            if (isChanged) {
              // 변경된게 있으면 업데이트 한다.
              this.staffInfo.degree.splice(this.index, 1, ioObject)
            } else {
              // 변경이 없으면
              // throw new Error('변경된 내용이 없습니다.')
            }
          } else {
            // 등록 모드인 경우
            this.staffInfo.degree.push(ioObject)
          }
          this.form.degree = JSON.stringify(this.staffInfo.degree)
        }

        // !! 실제 DB 처리
        const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        this.initDialog()

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 참고: 삭제 버튼 - 수정모드에서만 발동됨
    async remove () {
      try {
        // !! 수정 모드가 아니면 에러
        if (!(this.index || this.index > -1)) throw new Error('삭제할 수 없습니다.')

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `삭제 하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 370 })) {
          // 삭제 - 이건 바로 부모창에도 적용된다.
          this.staffInfo.degree.splice(this.index, 1)
          this.form.degree = JSON.stringify(this.staffInfo.degree)

          const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

          this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [폐지]: keypress 이벤트는 한글인 경우 e 가 찍히질 않는다. keydown, keyup 에서는 된다
    // 때문에 keydown, keyup 이벤트를 사용하며 e.target.id 와 this.$refs.참조 필요하다.
    // onlyNumber (e) {
    //   // backspace, delete, 숫자가 아니면 무시
    //   if (!((e.keyCode >= 48 && e.keyCode <= 57) || // 숫자
    //     e.keyCode === 8 || // backspace
    //     e.keyCode === 9 || // tab
    //     e.keyCode === 46 || // delete
    //     e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40 // 방향키
    //   )) {
    //     e.preventDefault()
    //   }
    //   // 한글을 제거한다. 지저분하지만 더 나은 방법이 없다.
    //   const elementId = e.target.id
    //   const filtered = e.target.value.match(/[^ㄱ-ㅎㅏ-ㅣ가-힣]*/i)[0]
    //   this.form[elementId] = filtered
    // },
    // 참고: form 과 넘겨받은 staffInfo 중 달라진게 있는지 검사한다.
    async isChangedForm (v) {
      try {
        if (this.staffInfo.degree[this.index].school !== v.school ||
          this.staffInfo.degree[this.index].major !== v.major ||
          this.staffInfo.degree[this.index].startY !== v.startY ||
          this.staffInfo.degree[this.index].endY !== v.endY
        ) {
          return true
        } else {
          return false
        }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
