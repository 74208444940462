<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>
      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid>
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.email"
                  label="이메일"
                  filled
                  shaped
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.name"
                  label="이름"
                  filled
                  shaped
                  readonly
                  hide-details
                ></v-text-field>
                <v-alert
                  type="info" dense text
                  class="mt-2 mb-6"
                >
                  이름 변경은 해당 계정의 [개인정보]에서 가능합니다.
                </v-alert>
              </v-col>
              <v-col cols="12" xs="12">
                <v-row>
                  <v-col cols="12" xs="12" sm="5" class="text-center">
                    <v-select
                      v-model="form.rank"
                      :items="ranks"
                      label="직위"
                      dense
                      menu-props="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="5" class="text-center">
                    <v-select
                      v-model="form.position"
                      :items="positions"
                      label="자격"
                      dense
                      menu-props="auto"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="2" class="mt-2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          v-on="on"
                        >mdi-help-circle-outline</v-icon>
                      </template>
                      <span>직위 및 자격의 추가, 변경, 삭제는 [관리자>카테고리관리]에서 가능합니다.</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12">
                <v-row
                  no-gutters
                  align="center"
                  justify="start"
                >
                  <div style="width:330px">
                    <v-text-field
                      ref="txtCode"
                      v-model.trim="form.code"
                      v-validate="'max:25'"
                      maxlength="25"
                      :error-messages="errors.collect('code')"
                      data-vv-name="code"
                      label="사원번호"
                      hint="ks-12345"
                      :error="isOverlabCode"
                    ></v-text-field>
                  </div>
                  <v-btn small text class="ma-2"
                    :color="mustChk ? 'error' : 'indigo'"
                    @click="chkCodeOverlap"
                  >
                    <v-icon small class="text-left">
                      {{ chkMulti ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline' }}
                    </v-icon>
                    중복체크
                  </v-btn>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.interphone"
                  label="구내전화"
                  v-validate="'max:50'"
                  :error-messages="errors.collect('interphone')"
                  data-vv-name="interphone"
                  hint="02-123-4567(100)"
                  maxlength="50"
                  counter="50"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.fax"
                  label="팩스번호"
                  v-validate="{max: 13, regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                  :error-messages="errors.collect('fax')"
                  data-vv-name="fax"
                  maxlength="13"
                  hint="02-123-4567"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.hp"
                  label="휴대전화"
                  v-validate="{max: 13, regex: [/^\d{2,3}-\d{3,4}-\d{4}$/]}"
                  :error-messages="errors.collect('hp')"
                  data-vv-name="hp"
                  maxlength="13"
                  hint="010-1234-5678"
                ></v-text-field>
              </v-col> -->
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.fax"
                  name="fax"
                  label="팩스번호"
                  placeholder="02-123-4567"
                  hint="02-123-4567"
                  type="text"
                  maxlength="50"
                  counter="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.hp"
                  name="hp"
                  label="휴대전화"
                  placeholder="010-1234-5678"
                  hint="010-1234-5678"
                  type="text"
                  maxlength="50"
                  counter="50"
                ></v-text-field>
              </v-col>
              <!-- <v-col cols="12" xs="12">
                <v-alert
                  border="left"
                  outlined
                  type="info"
                  class="text-body-2"
                  dense
                >
                  비밀번호의 변경은 [구글]에서 가능합니다.
                  <v-icon
                    small
                    color="primary lighten-2"
                    @click="gotoGoogle"
                    class="ml-1"
                  >mdi-launch</v-icon>
                </v-alert>
              </v-col> -->
            </v-row>
          </v-form>
        </v-container>
        <!-- <v-divider></v-divider> -->
      </v-card-text>
      <!-- <v-card-actions
        slot="footer"
        class="pa-3 mt-0"
      >
        <v-btn text icon small color="primary" disabled>
          <v-icon>print</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>get_app</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>phone_iphone</v-icon>
        </v-btn>
        <v-btn text icon small color="primary" disabled>
          <v-icon>local_post_office</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="text-caption mr-2">{{ staffInfo.wname }}</span><span class="text-caption">{{ staffInfo.updatedAt }}</span>
      </v-card-actions> -->

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

// filters
import isSpKey from '@/filters/isSpecialCharacter'

export default {
  components: {
    baseModal
  },

  props: [ 'staffInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      email: '',
      name: '',
      code: '',
      rank: '',
      position: '',
      hp: '',
      fax: '',
      interphone: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        code: '사원번호',
        interphone: '구내전화'
        // fax: '팩스',
        // hp: '휴대전화'
      }
    },
    mustChk: false, // 중복체크를 해야 하는지 여부
    chkMulti: false, // 참고: 사원번호 중복확인
    isOverlabCode: false, // 참고: 사원번호 중복시 수동으로 텍스트박스에 에러 표시
    ranks: [], // 직위
    positions: [] // 배열
  }),

  watch: {
    'form.code': { // !! 사원코드 자동감지 - 특수문자만 필터링
      handler: function (val, oldVal) {
        if (val.length > 0) { // 값이 있으면
          // 특수문자 입력 방지
          if (isSpKey(val)) {
            this.form.code = oldVal
            this.$refs.txtCode.lazyValue = oldVal
          }
          // 중복 체크 관련 초기화
          if (oldVal.length > 0) { // 중요: 여기만 이렇게 처리 - oldVal 체크까지 같이 하는 걸 추가
            // !! 팝업이 열릴 때 staffInfo 가 props 로 넘어와서 값이 매칭될 때
            // form.code가 변경되므로 여기서 잡힌다.
            // (props 로 부모로 부터 변수 매칭 받는데 시간이 오래 걸리는게 원인)
            this.mustChk = true // 중복체크 해야 함
            this.chkMulti = false // 중복확인을 안했다
            this.isOverlabCode = false // 중복은 아님
          }
        } else { // 내용이 아예 없으면 중복 체크하지 않아도 됨
          this.mustChk = false // 중복체크 안해도 됨
          this.chkMulti = false // 중복확인을 안했다
          this.isOverlabCode = false // 중복은 아님
        }
      }
    }
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)

    // 카테고리 : 직급구분 > 직위 = 14
    this.setCates('14').then(({ cates }) => {
      if (cates && cates.length > 0) this.ranks = cates.map(c => c.gubun1)
      else this.ranks = []
    })

    // 카테고리 : 직급구분 > 자격 = 15
    this.setCates('15').then(({ cates }) => {
      if (cates && cates.length > 0) this.positions = cates.map(c => c.gubun1)
      else this.positions = []
    })
  },

  methods: {
    isSpKey, // 특수문자 필터링
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 넘겨받은 staffInfo 변수를 매칭시키는게 초기화라고 볼 수 있다.
      this.form.email = this.staffInfo.email
      this.form.name = this.staffInfo.name
      this.form.code = this.staffInfo.code
      this.form.rank = this.staffInfo.rank
      this.form.position = this.staffInfo.position
      this.form.hp = this.staffInfo.hp
      this.form.fax = this.staffInfo.fax
      this.form.interphone = this.staffInfo.interphone

      this.mustChk = false
      this.chkMulti = false
      this.isOverlabCode = false

      // 수정시 : 팝업이 열리면서 사원번호가 비어있지 않은 경우 이미 사용중이므로 중복체크를 했다고 본다
      if (this.staffInfo.code) {
        this.chkMulti = true
      } else {
        // 사원번호가 비어있으면 중복체크도 초기화
        this.chkMulti = false
      }
    },
    open (title, options) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        if (this.mustChk && !this.chkMulti) {
          this.$refs.txtCode.focus()
          this.isOverlabCode = true
          throw new Error('사원번호 중복체크를 하셔야 합니다.')
        }

        // 폼의 내용 중 하나라도 변경된게 있는지 검사한다.
        const isChanged = await this.isChangedForm()
        if (isChanged) {
          // 새로고침하지 않게 부모페이지의 값도 바꿔버린다.
          this.$nextTick(function () {
            this.staffInfo = Object.assign(this.staffInfo, this.form)
          })

          // 변경된게 있으면 업데이트 한다.
          const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
          if (!data.success) throw new Error('수정할 수 없습니다. 다시 한 번 시도하시기 바랍니다')
        } else {
          // 변경이 없으면
          // throw new Error('변경된 내용이 없습니다.')
        }

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 참고: 사원번호 중복체크
    async chkCodeOverlap () {
      try {
        // console.log('>> ', this.$refs.txtCode.value)
        // 사원코드가 있고 넘어온 넘이랑 다르면 검사
        if (this.form.code && (this.form.code !== this.staffInfo.code)) {
          const { data } = await this.$axios.get(`admin/staff/chkCodeOverlap/${this.form.code}`)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.chk) {
            this.mustChk = true // 중복되었으니 다시 체크해야 한다
            this.chkMulti = false
            this.isOverlabCode = true
            this.$refs.txtCode.focus()
            throw new Error(`이미 등록된 사원번호 입니다.`)
          } else {
            this.mustChk = false // 중복체크 할 필요없다.
            this.chkMulti = true
            this.isOverlabCode = false
            this.$store.commit('SB_POP', { msg: '등록할 수 있는 사원번호입니다.', color: 'success' })
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 텍스트박스의 keyup 이벤트 - 사원번호 중복체크 리셋
    // async chkCodeOverlapReset (e) {
    //   // 사원코드가 있고 넘어온 넘이랑 다르면 검사
    //   if (this.form.code && (this.form.code !== this.staffInfo.code)) {
    //     this.chkMulti = false
    //     this.isOverlabCode = false
    //   } else {
    //     this.chkMulti = true
    //     this.isOverlabCode = false
    //   }
    // },
    // 참고: form 과 넘겨받은 staffInfo 중 달라진게 있는지 검사한다.
    async isChangedForm () {
      try {
        if (this.form.email !== this.staffInfo.email ||
          this.form.name !== this.staffInfo.name ||
          this.form.code !== this.staffInfo.code ||
          this.form.rank !== this.staffInfo.rank ||
          this.form.position !== this.staffInfo.position ||
          this.form.hp !== this.staffInfo.hp ||
          this.form.fax !== this.staffInfo.fax ||
          this.form.interphone !== this.staffInfo.interphone) {
          return true
        } else {
          return false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    gotoGoogle () {
      window.open('https://myaccount.google.com')
    },
    // !! 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
