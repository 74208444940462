<template>
  <v-container fluid class="pa-0 ma-0">

    <v-toolbar dense flat>
      <v-btn text small @click="$router.back()">
        <v-icon small>mdi-view-list</v-icon>
        목록
      </v-btn>

      <v-chip
        v-if="staffStatus"
        small
        color="primary"
      >재직중</v-chip>
      <v-chip
        v-else
        small
      >퇴사</v-chip>

      <!-- 재직/퇴직 상태 스위치는 액션이 아닌 보여주기 용도임 -->
      <!-- <v-switch
        v-model="staffStatus"
        inset
        color="primary"
        :label="staffStatus ? '재직' : '퇴직'"
        class="mt-5"
        readonly
      ></v-switch> -->

      <v-spacer></v-spacer>

      <v-btn
        v-show="!staffStatus"
        text
        small
        color="error"
        @click="deleteStaff">
        <v-icon small>mdi-trash-can-outline</v-icon>
        삭제
      </v-btn>
      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card
      title
      :elevation="0"
    >
      <v-row
        no-gutters
      >
        <v-col v-if="!isId" cols="12">
          <v-alert
            prominent
            type="warning"
            class="ma-4 pa-10"
          >존재하지 않는 데이터 입니다</v-alert>
        </v-col>
        <v-col v-else cols="12">
          <v-row
            no-gutters
          >
            <!-- 좌측 컨텐츠 시작 --->
            <v-col cols="12" md="8" lg="8" xl="8" >
              <v-row
                no-gutters
                align="center"
                justfy="center"
              >
                <v-col
                  md="auto"
                  lg="auto"
                  sm="4"
                >
                  <v-card
                    elevation="0"
                    tile
                    color="#ffffff"
                    class="text-center pa-1 ma-1"
                  >
                    <!-- 구글 프로필 사진 영역 -->
                    <v-avatar
                      class="profile"
                      color="#ffffff"
                      size="200"
                      tile
                    >
                      <!-- <v-img :src="sti.picture"></v-img> -->
                      <v-img
                        v-if="sti.picture"
                        :lazy-src="lazyImg"
                        :src="sti.picture"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-icon v-else size="200">mdi-account-box</v-icon>
                    </v-avatar>
                    <div class="text-end">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="primary" dark small>
                            mdi-help-circle-outline
                          </v-icon>
                        </template>
                        <!-- <span>프로필 사진의 등록/변경은 해당 아이디의 구글계정에서 가능합니다.</span> -->
                        <span>사진 변경은 해당 계정의 [개인정보]에서 가능합니다.</span>
                      </v-tooltip>
                    </div>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  md="8"
                  lg="8"
                  sm="8"
                >
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <v-col cols="12" class="pa-0">
                      <v-card
                        tile
                        elevation="0"
                        color="#ffffff"
                      >
                        <v-row class="ma-0 pa-0">
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">이름</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2 font-weight-bold blue--text text--darken-2">
                              {{ sti.name }}
                            </span>
                            <span class="text-body-2 ml-3 mr-3">
                              {{ (this.sti.rank && this.sti.position) ? `${this.sti.rank}|${this.sti.position}` : this.sti.rank || this.sti.position }}
                            </span>
                            <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                              <v-icon
                                small
                                @click="editStaffBasicInfo"
                                class="pb-1"
                              >mdi-plus-box-outline</v-icon>
                            </v-btn>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  v-on="on" small class="ml-1 mb-1" :color="mdiPlusBoxOutlineButtonColor"
                                >mdi-help-circle-outline</v-icon>
                              </template>
                              <span>이름 변경은 해당 계정의 [개인정보]에서 가능합니다.</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">사원번호</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2">{{ sti.code }}</span>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">구내전화</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2">{{ sti.interphone }}</span>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">팩스번호</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2">{{ sti.fax }}</span>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">휴대전화</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2">{{ sti.hp }}</span>
                            <!-- <v-icon
                              v-if="sti.hp"
                              small
                              class="ml-3"
                              @click="dummy"
                            >mdi-cellphone-iphone</v-icon> -->
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">이메일</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <v-hover v-slot:default="{ hover }">
                              <div>
                                <span class="text-body-2">{{ sti.email }}</span>
                                <v-tooltip right>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      v-show="sti.email"
                                      v-on="on"
                                      class="ml-1"
                                      :style="hover ? 'font-size: 1.2rem;' : 'font-size: 0.9rem;'"
                                      color="primary"
                                      @click="writeEmail(sti.email)"
                                    >
                                      {{ hover ? 'mdi-email' : 'mdi-email-outline' }}
                                    </v-icon>
                                  </template>
                                  <span>메일보내기</span>
                                </v-tooltip>
                              </div>
                            </v-hover>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">허용권한</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2">{{ sti.view2 }}</span>
                          </v-col>
                          <v-col cols="4" sm="4" class="pa-1 text-center">
                            <span class="text-body-2 grey--text text--darken-1">업무부서</span>
                          </v-col>
                          <v-col cols="8" sm="8" class="pa-1 text-start">
                            <span class="text-body-2" v-html="sti.view3"></span>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  md="12"
                  lg="12"
                >
                  <v-divider></v-divider>
                  <v-row
                    align="center"
                    justify="center"
                    no-gutters
                  >
                    <!-- 참고: 인적사항 -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">인적사항</span>
                      <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                        <v-icon
                          small
                          @click="editSti01"
                          class="pb-1"
                        >mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-2 px-2">
                      <v-hover v-slot:default="{ hover }">
                        <div class="text-body-2">
                          {{ personalInfo }}
                          <v-btn
                            v-if="personalInfo"
                            icon
                            small
                            :color="mdiListEditButtonColor"
                            class="pb-1"
                          >
                            <v-icon
                              v-show="hover"
                              small
                              @click="editSti01"
                            >edit</v-icon>
                          </v-btn>
                        </div>
                      </v-hover>
                    </v-col>
                    <!-- 참고: 학력 -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">학력</span>
                      <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                        <v-icon
                          small
                          @click="editSti02"
                          class="pb-1"
                        >mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-2 px-2">
                      <div
                        v-for="(d, i) in sti.degree"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="ma-0 pa-0">
                            <span class="text-body-2 text--primary">
                              <span v-show="d.startY">{{ d.startY }}</span><span v-show="d.startY && d.endY"> ~ </span><span v-show="d.endY">{{ d.endY }}</span>
                            </span>
                            <span v-show="d.startY || d.endY">)&nbsp;</span>
                            <span v-show="d.school"
                              class="mr-1 grey--text text--darken-2"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >
                              {{ d.school }}
                            </span>
                            <span v-show="d.major" class="mr-1">{{ d.major }}</span>
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editDegree(i)"
                              >edit</v-icon>
                            </v-btn>
                          </div>
                        </v-hover>
                      </div>
                    </v-col>
                    <!-- 참고: 면허|자격 -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">면허|자격</span>
                      <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                        <v-icon
                          small
                          @click="editSti03"
                          class="pb-1"
                        >mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-2 px-2">
                      <div
                        v-for="(d, i) in sti.license"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="ma-0 pa-0">
                            <span v-show="d.acqDate" class="mr-1">{{ strDateFormat(d.acqDate) }}</span>
                            <span v-show="d.licenseName"
                              class="mr-1 grey--text text--darken-2"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >
                              {{ d.licenseName }}
                            </span>
                            <span v-show="d.publisher" class="mr-1">({{ d.publisher }})</span>
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editLicense(i)"
                              >edit</v-icon>
                            </v-btn>
                          </div>
                        </v-hover>
                      </div>
                    </v-col>
                    <!-- 참고: 경력 -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <span class="text-body-2 grey--text text--darken-1 mr-3">경력</span>
                      <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                        <v-icon
                          small
                          @click="editSti04"
                          class="pb-1"
                        >mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-2 px-2">
                      <div
                        v-for="(d, i) in sti.career"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="ma-0 pa-0">
                            <span class="text-body-2 text--primary">
                              <span v-show="d.sY">{{ strDateFormat(d.sY) }}</span><span v-show="d.sY && d.eY"> ~ </span><span v-show="d.eY">{{ strDateFormat(d.eY) }}</span>
                            </span>
                            <span v-show="d.sY || d.eY">)&nbsp;</span>
                            <span v-show="d.coName"
                              class="mr-1 grey--text text--darken-2"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >
                              {{ d.coName }}
                            </span>
                            <span class="mr-1">
                              {{ (d.team || d.rank || d.upmu) ? '(' : '' }}{{ d.team }}{{ (d.team && d.rank) ? '|' : '' }}{{ d.rank }}{{ ((d.team || d.rank) && d.upmu) ? ' - ' : '' }}{{ d.upmu }}{{ (d.team || d.rank || d.upmu) ? ')' : '' }}
                            </span>
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editCareer(i)"
                              >edit</v-icon>
                            </v-btn>
                          </div>
                        </v-hover>
                      </div>
                    </v-col>
                    <!-- 참고: 이력서 -->
                    <v-col cols="12" class="pt-3 pb-1 px-2">
                      <span class="text-body-2 grey--text text--darken-1">이력서</span>
                      <v-btn v-show="!unableUpload" icon small :color="mdiPlusBoxOutlineButtonColor">
                        <v-icon
                          small
                          :disabled="unableUpload"
                          @click="showUpload"
                          class="pb-1"
                        >mdi-plus-box-outline</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-2 px-2">
                      <!-- 첨부파일 등록폼 -->
                      <div v-show="showFileInput" class="ma-0 pa-0">
                        <v-row no-gutters>
                          <v-col cols="6" class="text-left mb-2">
                            <v-file-input
                              ref="fileInput"
                              v-model="attachFile"
                              :rules="[rules.required, rules.fileCheck]"
                              :show-size="1000"
                              label=""
                              :placeholder="uploadMsg"
                              prepend-icon="mdi-paperclip"
                              :loading="loadingFile"
                              :disabled="loadingFile"
                              @change="attachFileChange"
                            >
                              <template v-slot:selection="{ text }">
                                <v-chip
                                  small
                                  label
                                  color="primary"
                                >
                                  {{ text }}
                                </v-chip>
                              </template>
                            </v-file-input>
                          </v-col>
                          <v-col class="text-left">
                            <v-btn
                              small
                              text
                              color="primary"
                              :loading="loadingFile"
                              :disabled="loadingFile"
                              class="ml-2 mt-7"
                              @click="uploadFile"
                            >
                              <v-icon small class="mr-2">mdi-cloud-upload</v-icon>
                              올리기
                            </v-btn>
                            <v-btn
                              small
                              text
                              color="error"
                              class="mt-7"
                              @click="cancelUpload"
                            >
                              <v-icon small class="mr-2">mdi-cancel</v-icon>취소
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                      <!-- 첨부파일 목록 -->
                      <div
                        v-for="file in attachedFiles"
                        :key="file.id"
                        class="my-1 text-body-2"
                      >
                        <v-hover v-slot:default="{ hover }" transition="fade-transition">
                          <div class="ma-0 pa-0">
                            <div>
                              <v-icon small :color="mdiDownloadButtonColor">mdi-paperclip</v-icon>
                              <span>{{ file.str1 }}</span>
                              <span class="ml-1 text-caption grey--text">
                                ({{ file.wname }} {{ strDateFormat2(file.createdAt) }})
                              </span>
                              <!-- 사용안함:[2021.9.1] 첨부파일 다운로드 -->
                              <!-- <v-icon
                                v-show="hover"
                                small
                                right
                                :color="mdiDownloadButtonColor"
                                class="ml-3"
                                @click="fileDownload(file)"
                              >mdi-cloud-download-outline</v-icon> -->
                              <!-- 첨부파일 바로보기 -->
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-show="hover"
                                    v-on="on"
                                    small
                                    right
                                    :color="mdiOpenFileButtonColor"
                                    class="ml-3"
                                    @click="fileDirectView(file)"
                                  >mdi-eye-outline</v-icon>
                                </template>
                                <span>바로보기</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-show="hover"
                                    v-on="on"
                                    small
                                    right
                                    color="error"
                                    class="ml-3"
                                    @click="deleteAttachFile(file)"
                                  >mdi-trash-can-outline</v-icon>
                                </template>
                                <span>삭제</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </v-hover>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" xs="12" class="my-8">
                  <div></div>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical class="ma-0 pa-0"></v-divider>
            <!-- 우측 컨텐츠 시작 --->
            <v-col>
              <v-container fluid class="ma-0 pa-0">
                <v-row
                  align="center"
                  justify="center"
                  no-gutters
                >
                  <!-- @: 특이사항 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-body-2 grey--text text--darken-1 mr-3">특이사항</span>
                    <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                      <v-icon
                        small
                        @click="editSti05"
                        class="pb-1"
                      >mdi-plus-box-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-3 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 grey--text text--darken-2 pa-1"
                      elevation="0"
                      max-height="150"
                      :outlined="sti.specifics ? true : false"
                    >
                      <div id="vue2editorViewer" v-html="sti.specifics"></div>
                    </v-card>
                  </v-col>
                  <!-- @: 발령내역 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">발령내역</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="editSti06"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ bal.totalItems }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 pa-1 ma-0"
                      elevation="0"
                      max-height="150"
                    >
                      <div
                        v-for="(d, i) in bal.list"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="mt-0 mb-3 pa-0">
                            <v-icon v-show="d.isStar" small color="yellow darken-2">mdi-star</v-icon>
                            {{ strDateFormat(d.date1) }}
                            {{ d.gubun1 ? `[${d.gubun1}]` : '' }}
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editBal(i, d)"
                              >edit</v-icon>
                            </v-btn>
                            <p
                              class="grey--text text--darken-2 ma-0 pa-0"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >{{ d.dta1 }}</p>
                          </div>
                        </v-hover>
                      </div>
                    </v-card>
                  </v-col>
                  <!-- @: 휴가|근태 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">휴가|근태</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="editSti07"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ hu.totalItems }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 pa-1 ma-0"
                      elevation="0"
                      max-height="150"
                    >
                      <div
                        v-for="(d, i) in hu.list"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="mt-0 mb-3 pa-0">
                            <v-icon v-show="d.isStar" small class="mr-1" color="yellow darken-2">mdi-star</v-icon>
                            <span v-show="d.date1" class="mr-2">{{ strDateFormat(d.date1) }}</span>
                            <v-chip
                              v-show="d.gubun1"
                              label x-small color="blue-grey darken-3" class="mr-2 px-1" outlined
                            >
                              <span class="text-caption font-weight-bold">{{ d.gubun1 }}</span>
                            </v-chip>
                            <span v-show="d.gubun2" class="mr-1 purple--text">[{{ d.gubun2 }}]</span>
                            <!-- <span v-show="d.d2" class="mr-1 red--text">
                              {{ d.gubun3 === '미반영' ? '' :
                                 d.gubun3 === '급여삭감' ? d.d2 ? '-' : ''
                                 : d.d2 ? '+' : ''}}{{ d.gubun3 === '미반영' ? '' : d.d2 !== 0 ? d.d2 : '' }}
                            </span> -->
                            <span v-show="d.d2 && d.d2 !== '0'"
                              class="mr-1"
                              :class="d.gubun3 === '급여삭감' ? 'info--text' : 'error--text'"
                            >
                              {{ d.gubun3 === '급여삭감' ? `-${d.d2}` : `+${d.d2}` }}
                            </span>
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editHu(i, d)"
                              >edit</v-icon>
                            </v-btn>
                            <p
                              class="grey--text text--darken-2 ma-0 pa-0"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >{{ d.dta1 }}</p>
                          </div>
                        </v-hover>
                      </div>
                    </v-card>
                  </v-col>
                  <!-- @: 인사평가 -->
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <v-row no-gutters>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">인사평가</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="editSti08"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">{{ insa.totalItems }}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 pa-1"
                      elevation="0"
                      max-height="150"
                    >
                      <div
                        v-for="(d, i) in insa.list"
                        :key="i"
                        class="text-body-2 py-0"
                      >
                        <v-hover v-slot:default="{ hover }">
                          <div class="mt-0 mb-3 pa-0">
                            <v-icon v-show="d.isStar" small color="yellow darken-2">mdi-star</v-icon>
                            {{ strDateFormat(d.date1) }}
                            {{ d.gubun1 ? `[${d.gubun1}]` : '' }}
                            <span class="primary--text ml-2">{{ d.gubun2 ? `${d.gubun2}` : '' }}</span>
                            <v-btn icon small :color="mdiListEditButtonColor" class="pb-1">
                              <v-icon
                                v-show="hover"
                                small
                                @click="editInsa(i, d)"
                              >edit</v-icon>
                            </v-btn>
                            <p
                              class="grey--text text--darken-2 ma-0 pa-0"
                              style="font-size: 0.925rem !important;font-weight: 500;"
                            >{{ d.dta1 }}</p>
                          </div>
                        </v-hover>
                      </div>
                    </v-card>
                  </v-col>
                  <!-- @: 전자결재, 인센티브는 보류로 주석처리 -->
                  <!-- <v-col cols="12" class="pt-3 pb-0 px-2">
                    <span class="text-body-2 grey--text text--darken-1 mr-3">인센티브</span>
                    <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                      <v-icon
                        small
                        @click="dummy"
                        class="pb-1"
                      >mdi-plus-box-outline</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 px-2">
                    20/20 만원
                  </v-col>
                  <v-col cols="12" class="pt-3 pb-0 px-2">
                    <v-row>
                      <v-col cols="6">
                        <span class="text-body-2 text-left grey--text text--darken-1 mr-3">전자결재</span>
                        <v-btn icon small :color="mdiPlusBoxOutlineButtonColor">
                          <v-icon
                            small
                            @click="dummy"
                            class="pb-1"
                          >mdi-plus-box-outline</v-icon>
                        </v-btn>
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="4" class="text-right mr-2">
                        <span class="text-body-2">7</span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-2 px-2">
                    <v-card
                      class="overflow-y-auto text-body-2 pa-1"
                      elevation="0"
                      max-height="100"
                    >
                      <p>19.10.25 홍길동 [결재중]</p>
                      <p>회계처리 건에 대한 결과물 / 하머하-1223</p>
                    </v-card>
                  </v-col> -->
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <!-- 조직원 정보 수정 팝업들 -->
    <staff-basic-info-dialog ref="staffBasicInfoDialog" :staffInfo="sti"></staff-basic-info-dialog>
    <edit-sti01-dialog ref="editSti01Dialog" :staffInfo="sti"></edit-sti01-dialog>
    <edit-sti02-dialog ref="editSti02Dialog" :staffInfo="sti"></edit-sti02-dialog>
    <edit-sti03-dialog ref="editSti03Dialog" :staffInfo="sti"></edit-sti03-dialog>
    <edit-sti04-dialog ref="editSti04Dialog" :staffInfo="sti"></edit-sti04-dialog>
    <edit-sti05-dialog ref="editSti05Dialog" :staffInfo="sti"></edit-sti05-dialog>
    <edit-sti06-dialog ref="editSti06Dialog" :staffInfo="sti" :bal="bal"></edit-sti06-dialog>
    <edit-sti07-dialog ref="editSti07Dialog" :staffInfo="sti" :hu="hu"></edit-sti07-dialog>
    <edit-sti08-dialog ref="editSti08Dialog" :staffInfo="sti" :insa="insa"></edit-sti08-dialog>

    <!-- !! 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
// 구분: filters
import strDateFormat from '@/filters/strDateFormat'
import strDateFormat2 from '@/filters/strDateFormat2'

import staffBasicInfoDialog from '@/components/admin/StaffBasicInfoDialog' // 기본정보
import editSti01Dialog from '@/components/admin/editSti01Dialog' // 인적사항
import editSti02Dialog from '@/components/admin/editSti02Dialog' // 학력
import editSti03Dialog from '@/components/admin/editSti03Dialog' // 면허|자격
import editSti04Dialog from '@/components/admin/editSti04Dialog' // 경력
import editSti05Dialog from '@/components/admin/editSti05Dialog' // 이력서
import editSti06Dialog from '@/components/admin/editSti06Dialog' // 발령내역
import editSti07Dialog from '@/components/admin/editSti07Dialog' // 휴가|근태
import editSti08Dialog from '@/components/admin/editSti08Dialog' // 인사평가

// @: pdfmake view
import {
  pdfMake,
  pdfViewStyle,
  menuTitle,
  rmTagReturnArray,
  viewType1,
  viewType2 } from '@/lib/pdfmakeView'

export default {
  components: {
    // 'viewer': Viewer,
    staffBasicInfoDialog,
    editSti01Dialog,
    editSti02Dialog,
    editSti03Dialog,
    editSti04Dialog,
    editSti05Dialog,
    editSti06Dialog,
    editSti07Dialog,
    editSti08Dialog
  },

  data: () => ({
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // staffEmail: '', // 폐지
    staffId: 0,
    staffStatus: true,
    // 참고: 조직원 정보 staffInfo
    sti: {
      name: '',
      view2: '',
      view3: '',
      degree: [],
      license: [],
      career: []
    },
    // 구분: 인적사항
    personalInfo: '',
    // 참고: 발령내역
    bal: {
      totalItems: 0,
      list: []
    },
    // 참고: 휴가|근태
    hu: {
      totalItems: 0,
      list: []
    },
    // 참고: 인사평가
    insa: {
      totalItems: 0,
      list: []
    },
    // @: 이력서 첨부파일
    attachFile: null, // 단일 첨부파일
    uploadLimit: 15, // 첨부파일 용량제한 15(MB)
    isLimitOver: false, // * 첨부파일 업로드 용량을 넘는지 여부 - 15MB를 넘는경우 true
    isCapacityOver: false, // * [2022.6.14]구글드라이브 사용가능 용량보다 큰지 여부
    availCapacity: 0, // * [2022.6.14]구글드라이브의 남은용량(사용가능량)
    showFileInput: false, // 이력서 첨부파일 열기 여부
    attachedFiles: [], // 첨부된 파일 목록
    unableUpload: true, // 업로드할 수 있는지 여부
    upFileCount: 1, // 업로드할 수 있는 파일 갯수
    loadingFile: false,
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      // pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 30, 25, 30, 25 ],
      content: []
    },
    // 구분: 존재하는 아이디 인지 여부
    isId: true,
    // 구분: 이미지 로딩시 기다릴때 이미지
    lazyImg: 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pjxzdmcgdmlld0JveD0iMCAwIDI0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjx0aXRsZS8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiBmaWxsPSIjNDY0NjQ2IiByPSI0Ii8+PHBhdGggZD0iTTIwLDE5djFhMSwxLDAsMCwxLTEsMUg1YTEsMSwwLDAsMS0xLTFWMTlhNiw2LDAsMCwxLDYtNmg0QTYsNiwwLDAsMSwyMCwxOVoiIGZpbGw9IiM0NjQ2NDYiLz48L3N2Zz4='
  }),

  computed: {
    // fileCheckRule () {
    //   return [
    //     value => !value || value.size < this.uploadLimit * 1000000 || `${this.uploadLimit}MB 이하의 파일을 선택하셔야 합니다.`
    //   ]
    // },
    // * data 에서는 this를 쓸 수 없으므로 computed 에서
    rules () {
      return {
        required: value => !!value || '입력값은 필수입니다',
        fileCheck: value => !value || value.size < this.uploadLimit * 1000000 || `${this.uploadLimit}MB 이하의 파일을 선택하셔야 합니다.`
      }
    },
    uploadMsg () {
      return `파일을 선택하세요(${this.uploadLimit}MB 이하)`
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // // 중요: 넘겨받은 이메일을 매칭한다.
    // this.staffEmail = this.$route.params.email
    // this.getStaffInfo() // 정보를 패칭해 와야 한다.

    // !! id 로 변경
    // console.log(this.$route.params)
    this.staffId = this.$route.params.id
    this.getStaffInfo() // 정보를 패칭해 와야 한다.
  },

  methods: {
    strDateFormat,
    strDateFormat2,
    dummy () {
      console.log('click dummy function!')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // * 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    // 목록으로
    goList () {
      this.$router.push('/admin/staffProfile')
    },
    // 이메일 쓰기(지메일) 바로가기
    async writeEmail (email) {
      try {
        // const gmailUrl = `https://mail.google.com/mail/u/0/#inbox?compose=new` // 쓰기로 바로간다.
        // const gmailUrl = `https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=new` // [2021.8.5] 느려서 주석처리
        const gmailUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${email}` // 쓰기로 바로간다.
        const link = document.createElement('a')
        link.href = gmailUrl
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 조직원 정보 가져오기
    async getStaffInfo () {
      try {
        if (!this.staffId) throw new Error(`아이디 정보가 없습니다`)

        // sti 변수 초기화
        this.sti = {
          name: '',
          view2: '',
          view3: '',
          degree: [],
          license: [],
          career: []
        }

        // !! profileById 로 변경
        const { data } = await this.$axios.get(`admin/staff/profileById/${this.staffId}`)
        if (!data.success) throw new Error(`데이터를 가져오지 못했습니다.: ${data.message}`)
        if (data.staffInfo) {
          // 참고: 조직원 정보 + 발령내역 + 휴가|근태 + 인사평가
          const { staffInfo, bal, hu, insa } = data
          // 조직원 정보 - staffInfo
          this.sti = staffInfo

          // 중요: 학력, 면허, 경력처리 - 초기값이 없는 경우 꼼수로 '[]' 를 준다
          if (!this.sti.degree) this.sti.degree = '[]'
          if (!this.sti.license) this.sti.license = '[]'
          if (!this.sti.career) this.sti.career = '[]'

          // 재직, 퇴직 여부
          this.staffStatus = staffInfo.status

          // 인적사항 구성
          await this.setPersonalInfo()

          // 발령내역
          this.bal = bal
          // 휴가|근태
          this.hu = hu
          // 인사평가
          this.insa = insa

          // 보여주는 함수로 뺀다
          this.displayStaffInfo()
          //
        } else {
          // throw new Error('존재하지 않는 데이터 입니다!')
          this.isId = false
          this.overlay = true
          setTimeout(this.goList, 1000)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 상세 페이지에 보이는 부분을 처리하는 함수
    async displayStaffInfo () {
      try {
        // 참고: 경고를 막기위해 코드변경 moment(date, 'YYYY-MM-DD HH:mm:ss') 처럼 써야 한다.
        this.sti.updatedAt = this.$moment(this.sti.updatedAt, 'YYYY-MM-DD HH:mm:ss').format('YYYY.MM.DD HH:mm')
        if (this.sti.birthday) {
          this.sti.birthday = this.$moment(this.sti.birthday, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
        }

        // 참고: 부여된 권한
        this.sti.view2 = ''
        let authArr = []
        if (this.sti.auth1 === 1) {
          authArr.push('계약+수금')
        }
        if (this.sti.auth2 === 1) {
          authArr.push('비용')
        }
        if (this.sti.auth3 === 1) {
          authArr.push('타임시트')
        }
        if (authArr.length > 0) {
          this.sti.view2 = authArr.join('|')
        }
        // 참고: 소속된 팀
        this.sti.view3 = ''
        if (this.sti.teams.length > 0) {
          let teamView = []
          this.sti.teams.forEach(team => {
            if (team.name !== '회의실관리팀') { // !![2021.7.27] 회의실관리팀 제거(차후 복구)
              const colors = team.color.split(' ')
              const color2 = colors.length === 2 ? ` text--${colors[1]}` : ''
              const txtColor = `${colors[0]}--text${color2}`
              teamView.push(`<span class="${txtColor}">${team.name}</span>`)
            }
          })
          this.sti.view3 = teamView.join('|')
        }

        // 참고: 학력 배열을 만들기 위해 JSON.parse
        if (this.sti.degree) {
          this.sti.degree = JSON.parse(this.sti.degree)
        }

        // 참고: 면허|자격 배열을 만들기 위해 JSON.parse
        if (this.sti.license) {
          this.sti.license = JSON.parse(this.sti.license)
        }

        // 참고: 경력 배열을 만들기 위해 JSON.parse
        if (this.sti.career) {
          this.sti.career = JSON.parse(this.sti.career)
        }

        // !! 첨부파일 목록 패칭
        await this.getAttachedFiles(this.sti.id)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 조직원 기본정보 편집창
    async editStaffBasicInfo () {
      try {
        if (await this.$refs.staffBasicInfoDialog.open('기본정보', { width: 550 })) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 인적사항 편집창
    async editSti01 () {
      try {
        if (await this.$refs.editSti01Dialog.open('인적사항', { width: 550 })) {
          await this.setPersonalInfo()
          // console.log(this.sti)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 학력 등록창
    async editSti02 () {
      try {
        if (await this.$refs.editSti02Dialog.open('학력', { width: 450 }, -1)) {
          if (this.sti.degree) {
            // console.log(this.sti.degree)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 학력 편집창
    async editDegree (index) {
      try {
        if (await this.$refs.editSti02Dialog.open('학력', { width: 450 }, index)) {
          if (this.sti.degree) {
            // console.log(this.sti.degree)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 면허|자격 등록창
    async editSti03 () {
      try {
        if (await this.$refs.editSti03Dialog.open('면허|자격', { width: 450 }, -1)) {
          if (this.sti.license) {
            // console.log(this.sti.license)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 면허|자격 편집창
    async editLicense (index) {
      try {
        if (await this.$refs.editSti03Dialog.open('면허|자격', { width: 450 }, index)) {
          if (this.sti.license) {
            // console.log(this.sti.license)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 경력 등록창
    async editSti04 () {
      try {
        if (await this.$refs.editSti04Dialog.open('경력', { width: 450 }, -1)) {
          if (this.sti.career) {
            // console.log(this.sti.career)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 경력 편집창
    async editCareer (index) {
      try {
        if (await this.$refs.editSti04Dialog.open('경력', { width: 450 }, index)) {
          if (this.sti.career) {
            // console.log(this.sti.career)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 특이사항 등록/편집창
    async editSti05 () {
      try {
        if (await this.$refs.editSti05Dialog.open('특이사항', { width: 700 })) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 발령내역 등록창
    async editSti06 () {
      try {
        if (await this.$refs.editSti06Dialog.open('발령', { width: 500 }, -1, {})) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 발령내역 편집창
    async editBal (index, obj) {
      try {
        obj.date1 = obj.date1.substr(0, 10)
        if (await this.$refs.editSti06Dialog.open('발령', { width: 500 }, index, obj)) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 휴가|근태 등록창
    async editSti07 () {
      try {
        if (await this.$refs.editSti07Dialog.open('휴가|근태', { width: 500 }, -1, {})) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 휴가|근태 편집창
    async editHu (index, obj) {
      try {
        obj.date1 = obj.date1.substr(0, 10)
        if (await this.$refs.editSti07Dialog.open('휴가|근태', { width: 500 }, index, obj)) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 인사평가 등록창
    async editSti08 () {
      try {
        // console.log(this.$store.state.ui.name, ':', this.$store.state.ui.email)
        if (await this.$refs.editSti08Dialog.open('인사평가', { width: 500 }, -1, {})) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 인사평가 편집창
    async editInsa (index, obj) {
      try {
        obj.date1 = obj.date1.substr(0, 10)
        if (await this.$refs.editSti08Dialog.open('인사평가', { width: 500 }, index, obj)) {
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 인적사항 적용
    async setPersonalInfo () {
      try {
        // 인적사항 구성
        this.personalInfo = ''
        let pia = []
        if (this.sti.birthday) {
          if (this.sti.isLunar) {
            pia.push('(-)')
          } else {
            pia.push('(+)')
          }
          pia.push(strDateFormat(this.sti.birthday))
        }
        if (this.sti.isMarry) {
          pia.push('[기혼]')
        } else {
          pia.push('[미혼]')
        }
        if (this.sti.addr) {
          pia.push(this.sti.addr)
        }
        if (this.sti.emergency) {
          pia.push(this.sti.emergency)
        }
        if (this.sti.hobby) {
          pia.push(`(${this.sti.hobby})`)
        }
        if (this.sti.etc) {
          pia.push(this.sti.etc)
        }
        if (pia.length > 1) {
          this.personalInfo = pia.join(' ')
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // @:@: 이력서 첨부파일 처리 영역
    // 구분: 이력서 첨부파일 등록창 열기
    async showUpload () {
      try {
        this.showFileInput = !this.showFileInput

        // ! [2022.6.20 추가] 구글드라이브 용량정보 패칭 - 실시간으로 남은 용량을 알아보자
        const { data } = await this.$axios.get(`admin/staff/getGoogleDriveQuota`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        if (data.gdQuoat) {
          // * 구글 드라이브의 사용할 수 있는 용량 = limit - usage
          this.availCapacity = parseInt(data.gdQuoat.limit, 10) - parseInt(data.gdQuoat.usage, 10)
          // console.log(this.availCapacity)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 영역의 change event handler
    async attachFileChange () {
      try {
        if (this.attachFile) { // 첨부파일이 들어온 경우만
          // # 업로드 제한 용량을 넘는가? 15MB = 15728640Bytes 이지만 15000000 으로 맞춰준다.
          // const upLimit = this.uploadLimit * (1024 * 1024 = 1048576)
          const upLimit = this.uploadLimit * 1000000
          if (this.attachFile.size > upLimit) {
            // * 제한용량이 넘은 경우
            this.isLimitOver = true
          } else {
            // * 제한용량이 안넘은 경우
            this.isLimitOver = false

            // # [2022.6.20]구글드라이브의 사용가능한 용량보다 크면 안된다.
            if (this.attachFile.size > this.availCapacity) {
              // * 구글 드라이브 사용가능용량보다 크다
              this.isCapacityOver = true
            } else {
              this.isCapacityOver = false
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 업로드 처리
    async uploadFile () {
      try {
        // * 파일폼에 에러가 발생한 경우
        if (!this.$refs.fileInput.validate()) {
          this.$refs.fileInput.focus()

          // # 용량이 넘는 경우도 여기로 올 수 있다.
          if (this.isLimitOver) {
            // 팝업 에러창
            const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
            if (!pop) throw new Error('팝업창을 열 수 없습니다.')
            // 찾았으면 팝업을 연다
            const msg = '업로드 가능한 파일용량이 초과되었습니다<br><strong>15MB이하의 파일</strong>만 가능합니다'
            if (await pop.open('용량초과', msg, { color: 'error', width: 400 })) {
              this.attachFile = null
              this.isLimitOver = false
              throw new Error('입력값을 확인하세요.')
            }
          }
          throw new Error('입력값을 확인하세요.')
        }

        // * 업로드 용량이 넘는 경우 경고
        if (this.isLimitOver) {
          this.attachFile = null
          throw new Error(this.uploadMsg)
        }

        // * 업로드 가능 갯수를 넘었다
        if (this.unableUpload) {
          this.attachFile = null
          throw new Error('업로드 할 수 없습니다. 업로드 가능 갯수를 넘습니다.')
        }

        // # [2022.6.20]구글드라이브 사용가능 용량이 넘는 경우 경고
        if (this.isCapacityOver) {
          // 팝업 에러창
          const pop = await this.findParentRefs(this.$parent, 'ConfirmDialogNoCancel')
          if (!pop) throw new Error('팝업창을 열 수 없습니다.')
          // 찾았으면 팝업을 연다
          const msg = `구글 드라이브의 사용가능한 용량을 초과했습니다.<br><strong>관리자계정의 구글드라이브</strong>를 확인하시기 바랍니다.`
          if (await pop.open('드라이브 용량초과', msg, { color: 'error', width: 400 })) {
            this.attachFile = null
            this.isCapacityOver = false
            throw new Error(`입력값을 확인하세요.`)
          }
        }

        // !! FormData를 사용하면 자동으로 'Content-Type': 'multipart/form-data' 가 들어가므로 헤더 설정이 필요없다!
        let formData = new FormData()

        if (this.attachFile) {
          // 파일데이터 추가 - 인자가 3개일때는 3번째는 파일명을 넣을 수 있다(매뉴얼 첨고)
          formData.append('attachFile', this.attachFile, this.attachFile.name)

          // 부가 데이터 추가
          formData.append('num', 1) // 이력서는 무조건 1임
          formData.append('type', 36) // 타입: 36(첨부파일)
          formData.append('depth', 2) // depth: 2(2단계 깊이)
          formData.append('pId', this.sti.id) // manage.users 테이블의 조직원 id
          formData.append('pType', 99) // manage.users 테이블의 코드번호
          formData.append('gubun1', this.sti.email) // 조직원 이메일

          // 전송전에 로딩걸기
          this.loadingFile = true
          // 데이터 전송
          const { data } = await this.$axios.post(`admin/staff/uploadFile`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          this.$refs.fileInput.resetValidation() // 유효성체크 초기화
          this.loadingFile = false // 로딩 제거
          this.attachFile = null // 파일변수 초기화
          this.showFileInput = false // 닫기

          // !! 첨부파일 목록 리프레시
          await this.getAttachedFiles(this.sti.id)
        } else {
          throw new Error(`업로드 할 파일을 선택하십시오`)
        }
      } catch (e) {
        this.$refs.fileInput.resetValidation() // 유효성체크 초기화
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 업로드 취소
    async cancelUpload () {
      try {
        this.$refs.fileInput.resetValidation() // 유효성체크 초기화

        this.isLimitOver = false
        this.isCapacityOver = false

        this.loadingFile = false
        this.attachFile = null
        this.showFileInput = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 목록 가져오기
    async getAttachedFiles (id) {
      try {
        const { data } = await this.$axios.get(`admin/staff/getAttachedFiles/${id}`)
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
        this.attachedFiles = data.list
        // 업로드 파일 갯수 제한이 넘으면 업로드 할 수 없다!
        if (this.attachedFiles.length >= this.upFileCount) {
          this.unableUpload = true
        } else {
          this.unableUpload = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.9.1] 첨부파일 다운로드
    async fileDownload (file) {
      try {
        this.overlay = true // 로딩 오버레이 on

        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우(관리대행자겠지?) .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`admin/staff/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우(관리대행자겠지?) .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`admin/staff/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 첨부파일 삭제
    async deleteAttachFile (file) {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>관리자의 구글 드라이브에는 유지됩니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          // console.log(file.id)
          // 삭제할 첨부파일 아이디 전송
          const formData = { fileId: file.id }
          const { data } = await this.$axios.post(`admin/staff/deleteAttachFile`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // !! 첨부파일 목록 리프레시
          await this.getAttachedFiles(this.sti.id)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    async deleteStaff () {
      try {
        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `정말 삭제하시겠습니까?<br>기본 정보 및 하위 정보 일체를 삭제합니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 400 })) {
          //
          if (!this.sti) throw new Error('삭제할 수 없습니다.')
          const params = { id: this.sti.id, email: this.sti.email }

          const { data } = await this.$axios.post(`admin/staff/deleteStaff`, params)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

          // 삭제 후 목록으로 이동
          this.redirect(`/admin/staffProfile`)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 상세뷰 제너레이터
    // 중요: /src/lib/pdfmakeView.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: '인사정보 상세뷰',
          subject: '인사정보 상세뷰'
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfViewStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // @: 헤더 타이틀
        const pdfHeaderTitle = `인사정보 - ${this.sti.name}`

        // @: 작성일 => 최종수정일
        const workDate = `작성: ${this.$moment().format('YYYY.MM.DD HH:mm')}`

        // !! 기본내용
        let basicContentBody = [
          {
            stack: [
              {
                ul: [
                  `이름: ${this.sti.name}`,
                  `직위: ${this.sti.rank}`,
                  `자격: ${this.sti.position}`,
                  `사원번호: ${this.sti.code}`,
                  `구내전화: ${this.sti.interphone}`,
                  `팩스번호: ${this.sti.fax}`,
                  `휴대전화: ${this.sti.hp}`,
                  `이메일: ${this.sti.email}`,
                  `허용권한: ${this.sti.view2}`,
                  `업무부서: ${this.sti.teams.map(t => t.name).join('|')}`
                ]
              }
            ],
            border: [false, false, false, false]
          }
        ]

        // 인적사항
        let pInfo = []
        if (this.personalInfo) {
          pInfo.push([ { text: this.personalInfo, border: [false, false, false, false] } ])
        }

        // 학력
        let rDegree = []
        this.sti.degree.forEach(item => {
          let text = `${item.startY} ~ ${item.endY} ${item.school} ${item.major} `
          rDegree.push([ { text, border: [false, false, false, false] } ])
        })

        // 면허|자격
        let rLicense = []
        this.sti.license.forEach(item => {
          let text = `${strDateFormat(item.acqDate)} ${item.licenseName} ${item.publisher ? `(${item.publisher}) ` : ''}`
          rLicense.push([ { text, border: [false, false, false, false] } ])
        })

        // 경력
        let rCareer = []
        this.sti.career.forEach(item => {
          // (부서|직윈 - 업무) 의 형식으로 만들기 위해..
          let aa1_ = []
          let aa2_ = []
          if (item.team) {
            aa1_.push(item.team)
          }
          if (item.rank) {
            aa1_.push(item.rank)
          }
          if (aa1_.length > 0) {
            aa2_.push(aa1_.join('|'))
          }
          if (item.upmu) {
            aa2_.push(item.upmu)
          }
          let text = `${strDateFormat(item.sY)} ~ ${strDateFormat(item.eY)} ${item.coName}${aa2_.length > 0 ? ` (${aa2_.join(' - ')})` : ' '}`
          rCareer.push([ { text, border: [false, false, false, false] } ])
        })

        // 이력서
        let attfiles = []
        this.attachedFiles.forEach(item => {
          let text = `${item.str1} (${item.wname} ${strDateFormat2(item.createdAt)})`
          attfiles.push([ { text, border: [false, false, false, false] } ])
        })

        // 특이사항
        let rTxt1 = rmTagReturnArray(this.sti.specifics)

        // 발령내역
        let rBals = []
        this.bal.list.forEach(item => {
          let text = `${item.isStar ? '★ ' : ''}${strDateFormat(item.date1)} ${item.gubun1 ? `${item.gubun1}` : ''}`
          text += ` ${item.dta1 ? ` - ${item.dta1.replace(/\n/gi, ' ')}` : ''}`
          // if (item.dta1) {
          //   text += `\n${item.dta1}`
          // }
          rBals.push([ { text, border: [false, false, false, false] } ])
        })

        // 휴가|근태
        let rHus = []
        this.hu.list.forEach(item => {
          let text = `${item.isStar ? '★ ' : ''}${strDateFormat(item.date1)} [${item.gubun1}] [${item.gubun2}]`
          if (item.gubun3 !== '미반영' && item.d2) {
            text += ` ${item.gubun3 === '급여삭감' ? '-' : '+'}${item.d2}`
          }
          text += ` ${item.dta1 ? ` - ${item.dta1.replace(/\n/gi, ' ')}` : ''}`
          // if (item.dta1) {
          //   text += `\n${item.dta1}`
          // }
          rHus.push([ { text, border: [false, false, false, false] } ])
        })

        // 인사평가
        let rInsas = []
        this.insa.list.forEach(item => {
          let text = `${item.isStar ? '★ ' : ''}${strDateFormat(item.date1)}${item.gubun1 ? ` [${item.gubun1}]` : ''}${item.gubun2 ? ` [${item.gubun2}]` : ''}`
          text += ` ${item.dta1 ? ` - ${item.dta1.replace(/\n/gi, ' ')}` : ''}`
          // if (item.dta1) {
          //   text += `\n${item.dta1}`
          // }
          rInsas.push([ { text, border: [false, false, false, false] } ])
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: workDate, style: 'subheader' },
          menuTitle('기본내용'),
          viewType1(basicContentBody),
          menuTitle('인적사항'),
          viewType2(pInfo),
          menuTitle('학력'),
          viewType2(rDegree),
          menuTitle('면허|자격'),
          viewType2(rLicense),
          menuTitle('경력'),
          viewType2(rCareer),
          menuTitle('이력서'),
          viewType2(attfiles),
          menuTitle('특이사항'),
          viewType1(rTxt1),
          menuTitle('발령내역'),
          viewType2(rBals),
          menuTitle('휴가|근태'),
          viewType2(rHus),
          menuTitle('인사평가'),
          viewType2(rInsas)
        ]

        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
