<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="options.width"
    @keydown.esc="cancel"
    v-bind:style="{zIndex: options.zIndex}"
  >
    <baseModal>

      <v-btn
        slot="positive-button"
        dark text class="orange--text text-h6 font-weight-bold" @click.native="agree">등록</v-btn>

      <v-btn
        v-if="index > -1"
        slot="func-button"
        dark text class="pink--text text--lighten-2 text-h6" @click.native="remove">삭제</v-btn>

      <v-btn
        slot="negative-button"
        dark text class="white--text subheading" @click.native="cancel">취소</v-btn>

      <v-toolbar-title
        slot="title-area"
        class="white--text">{{ title }}</v-toolbar-title>

      <v-card-text
        slot="content"
      >
        <v-container fluid class="pb-0">
          <v-form ref="form">
            <v-row
              align="center"
              justify="center"
              no-gutters
            >
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.coName"
                  v-validate="'required|max:30'"
                  maxlength="30"
                  :error-messages="errors.collect('coName')"
                  data-vv-name="coName"
                  required
                  label="*업체명"
                  hint="법무법인 현우엘앤씨"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-menu
                  ref="sYSelector"
                  v-model="sYSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="form.sY"
                      label="입사일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 170px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="pickerSY"
                    v-model="form.sY"
                    locale="ko"
                    type="month"
                    :active-picker.sync="activePicker1"
                    :max="today"
                    min="1950-01-01"
                    @change="saveSY"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" xs="12" class="text-right">
                <v-menu
                  ref="eYSelector"
                  v-model="eYSelector"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model.trim="form.eY"
                      label="퇴사일"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      style="max-width: 180px;"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="pickerEY"
                    v-model="form.eY"
                    locale="ko"
                    type="month"
                    :active-picker.sync="activePicker2"
                    :max="today"
                    min="1950-01-01"
                    @change="saveEY"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  v-model.trim="form.team"
                  v-validate="'max:20'"
                  maxlength="20"
                  :error-messages="errors.collect('team')"
                  data-vv-name="team"
                  label="부서"
                  hint="민사팀"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="6" xs="12">
                <v-text-field
                  v-model.trim="form.rank"
                  v-validate="'max:20'"
                  maxlength="20"
                  :error-messages="errors.collect('rank')"
                  data-vv-name="rank"
                  label="직위"
                  hint="구성변호사"
                  class="pa-2"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12">
                <v-text-field
                  v-model.trim="form.upmu"
                  v-validate="'max:30'"
                  maxlength="30"
                  :error-messages="errors.collect('upmu')"
                  data-vv-name="upmu"
                  label="업무"
                  hint="민사소송 전반"
                  class="pa-2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

    </baseModal>

  </v-dialog>
</template>

<script>
import baseModal from '@/components/common/BaseModal' // 기본 다이얼로그
import ko from 'vee-validate/dist/locale/ko'

export default {
  components: {
    baseModal
  },

  props: [ 'staffInfo' ],

  $_veeValidate: {
    validator: 'new'
  },

  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    options: {
      color: 'primary',
      width: 500,
      zIndex: 200
    },
    // 참고: for Form
    form: {
      email: '',
      career: '', // 참고: 백엔드에 넘기기 위해 필요하다!
      type: 'career', // 참고: 백엔드에 타입 정보를 알린다
      coName: '',
      sY: '',
      eY: '',
      team: '',
      rank: '',
      upmu: ''
    },
    dictionary: {
      messages: ko.messages,
      attributes: {
        coName: '업체명'
        // sY: '입사일',
        // eY: '퇴사일',
        // team: '부서',
        // rank: '직위',
        // upmu: '업무'
      }
    },
    activePicker1: null, // 입사일 셀렉터 date picker 의 타입결정인자
    activePicker2: null, // 퇴사일 셀렉터 date picker 의 타입결정인자
    sYSelector: false, // 입사일 셀렉터 메뉴
    eYSelector: false, // 취득일 셀렉터 메뉴
    index: -1, // 수정 인덱스
    today: ''
  }),

  watch: {
    sYSelector (val) { // 입사일 셀렉터의 초기모드를 년선택으로 한다
      val && setTimeout(() => (this.activePicker1 = 'YEAR'))
    },
    eYSelector (val) { // 퇴사일 셀렉터의 초기모드를 년선택으로 한다
      val && setTimeout(() => (this.activePicker2 = 'YEAR'))
    }
  },

  created () {
    this.today = this.$moment().format('YYYY-MM-DD')
  },

  mounted () {
    this.$validator.localize('ko', this.dictionary)
  },

  methods: {
    dummy () {
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    // !!중요: 재귀적으로 부모의 $refs 를 탐색하여 target 객체를 찾아 리턴한다.
    // 주로 팝업을 검색하는데 사용!
    async findParentRefs (parent, target) {
      try {
        for (let key in parent.$refs) {
          if (key === target) { // 찾은경우
            return parent.$refs[key]
          }
        }
        // 못찾은 경우 - 부모가 또 있으면 올라간다.
        if (parent.$parent) {
          return await this.findParentRefs(parent.$parent, target)
        } else {
          return null // 못찾으면 null 리턴
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 초기화 함수
    initDialog () {
      // form validation 초기화
      this.$validator.reset()

      // 참고: staffInfo 변수중 이메일만 받는다
      this.form.email = this.staffInfo.email

      // 참고: 아래의 폼 변수는 따로 초기화 해야 한다.
      this.form.type = 'career'
      this.form.coName = ''
      this.form.sY = ''
      this.form.eY = ''
      this.form.team = ''
      this.form.rank = ''
      this.form.upmu = ''

      this.index = -1 // 참고: 수정용 인덱스 초기화
    },
    open (title, options, index = -1) {
      try {
        this.dialog = true
        this.title = title
        this.options = Object.assign(this.options, options)

        this.initDialog()

        // 참고: 편집 모드인 경우 - 넘어온 객체를 this.form 에 복사
        if (index > -1) {
          this.index = index
          this.form = Object.assign(this.form, this.staffInfo.career[index])
        }

        // 프로미스를 리턴하면서 resolve를 매칭하고있다.ddㅁㅁ
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 참고: 등록 버튼
    async agree () {
      try {
        if (!this.form.email) throw new Error('정상적인 데이터가 아닙니다.')
        // console.log(this.form)

        // 참고: 입력값 검증
        let isValidate = await this.$validator.validateAll()
        if (!isValidate) throw new Error('입력값을 확인해 주세요')

        const { coName, sY, eY, team, rank, upmu } = this.form

        // 데이터가 정상이면 객체를 만들자
        const ioObject = { coName, sY, eY, team, rank, upmu }

        if (!this.staffInfo.career) {
          // 참고: 기존의 배열이 없으면.. 최초의 배열 생성
          const imsiArr = []
          imsiArr.push(ioObject)
          this.form.career = JSON.stringify(imsiArr)
          // 중요: 부모의 배열에 넣어줘야 함
          this.staffInfo.career.push(ioObject)
        } else {
          // 참고: 기존의 배열이 있으면 .. 편집과 등록에 따라 다르게 처리
          if (this.index > -1) {
            // 편집모드인 경우 해당 항목을 대치한다.
            const isChanged = await this.isChangedForm(ioObject)
            if (isChanged) {
              // 변경된게 있으면 업데이트 한다.
              this.staffInfo.career.splice(this.index, 1, ioObject)
            } else {
              // 변경이 없으면
              // throw new Error('변경된 내용이 없습니다.')
            }
          } else {
            // 등록 모드인 경우
            this.staffInfo.career.push(ioObject)
          }
          this.form.career = JSON.stringify(this.staffInfo.career)
        }

        const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        this.initDialog()

        this.resolve(true)
        this.dialog = false
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 취소 버튼
    cancel () {
      // 다이얼로그를 닫으면서 초기화 해야 한다!
      this.initDialog()
      this.resolve(false) // reject() 가 아니라 resolve(false)로 던져야 한다.
      this.dialog = false
    },
    // 참고: 삭제 버튼 - 수정모드에서만 발동됨
    async remove () {
      try {
        // !! 수정 모드가 아니면 에러
        if (!(this.index || this.index > -1)) throw new Error('삭제할 수 없습니다.')

        // 중요: 재귀적으로 부모의 $refs 에서 팝업 컴포넌트 객체를 얻는다.
        const target = 'confirmDialog'
        const pop = await this.findParentRefs(this.$parent, target)
        if (!pop) throw new Error('팝업창을 열 수 없습니다.')
        // 찾았으면 팝업을 연다
        const msg = `삭제 하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.`
        if (await pop.open('삭제', msg, { color: 'error', width: 370 })) {
          // 삭제 - 이건 바로 부모창에도 적용된다.
          this.staffInfo.career.splice(this.index, 1)
          this.form.career = JSON.stringify(this.staffInfo.career)

          const { data } = await this.$axios.post('admin/staff/editStaffProfile', this.form)
          if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

          this.resolve(true)
          this.dialog = false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 참고: form 과 넘겨받은 staffInfo 중 달라진게 있는지 검사한다.
    async isChangedForm (v) {
      try {
        if (this.staffInfo.career[this.index].coName !== v.coName ||
          this.staffInfo.career[this.index].sY !== v.sY ||
          this.staffInfo.career[this.index].eY !== v.eY ||
          this.staffInfo.career[this.index].team !== v.team ||
          this.staffInfo.career[this.index].rank !== v.rank ||
          this.staffInfo.career[this.index].upmu !== v.upmu
        ) {
          return true
        } else {
          return false
        }
      } catch (e) {
        this.sbpop(e)
      }
    },

    // 참고: 입사일 셀렉트 선택시 저장 함수
    saveSY (date) {
      this.$refs.sYSelector.save(date)
    },

    // 참고: 퇴사일 셀렉트 선택시 저장 함수
    saveEY (date) {
      this.$refs.eYSelector.save(date)
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
